import React from "react"

import { Page, Heading, Text, Flex } from "../app/components"

const NotFoundPage = () => (
  <Page seo="404: Not found">
    <Flex
      column
      sx={{
        flex: 1,
        maxWidth: "600px",
        px: "32px",
        mx: "auto",
        justifyContent: "center",
      }}
    >
      <Heading variant="text.pageTitle">404: Not found</Heading>
      <Text variant="text.textBody">
        You just hit a route that doesn&#39;t exist...
      </Text>
    </Flex>
  </Page>
)

export default NotFoundPage
